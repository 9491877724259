import components_utils from '@/Themes/utils/components_utils.js';

const components = {
    MuiLink: {
        ...components_utils.MuiLink,
    },
    MuiButton: {
        styleOverrides: {
            root: {
                lineHeight: 1.25,
                textTransform: 'none',
                boxShadow: 'none',
            },
            text: ({ theme }) => ({
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                },
            }),
            contained: ({ theme }) => ({
                color: 'white',
                '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                    boxShadow: 'none',
                },
            }),
            containedPrimary: {
                color: 'black',
            },
            sizeLarge: {
                padding: '0 1.5rem',
                minHeight: '60px',
            },
        },
    },
    MuiTextField: {
        ...components_utils.MuiTextField,
    },
};

export default components;
