import breakpoints from '@/Themes/eureka/breakpoints.js';
import components from '@/Themes/eureka/components.js';
import palette from '@/Themes/eureka/palette.js';
import typography from '@/Themes/eureka/typography.js';
import utils from '@/Themes/utils/utils.js';
import { createTheme } from '@mui/material';
import { frFR } from '@mui/material/locale';

const theme = createTheme(
    {
        ...utils,
        breakpoints: { ...breakpoints },
        palette: { ...palette },
        typography: { ...typography },
        components: { ...components },
    },
    frFR,
);

export default theme;
