import { Link } from '@inertiajs/react';
import { Button } from '@mui/material';
import { forwardRef, memo } from 'react';

const InertiaButton = forwardRef(
    ({ children, href, method, component, centerRipple, focusRipple, style, ...props }, ref) => {
        const is_button = method && ['post', 'patch', 'put', 'delete'].includes(method.toLowerCase());
        return (
            <Link
                href={href}
                method={method}
                className={is_button ? 'unstyled-button' : ''}
                style={style}
                {...(is_button ? { as: 'button' } : {})}
            >
                <Button component="span" tabIndex={-1} {...props}>
                    {children}
                </Button>
            </Link>
        );
    },
);

export default memo(InertiaButton);
