import './bootstrap';
import '../css/app.css';
import { NotificationsProvider } from '@/Components/Providers/NotificationsProvider.jsx';
import MainLayout from '@/Layouts/MainLayout.jsx';
import { loadFont } from '@/Scripts/Common/fonts.js';
import { default as couleur_villas_theme } from '@/Themes/couleur-villas/theme.js';
import { default as eureka_theme } from '@/Themes/eureka/theme.js';
import { default as ya_k_theme } from '@/Themes/ya-k/theme.js';
import { createInertiaApp } from '@inertiajs/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createRoot } from 'react-dom/client';

//const appName = import.meta.env.VITE_APP_NAME || 'Eureka V2';

axios.interceptors.request.use(
    (config) => {
        if (config?.options) {
            config = { ...config, ...config.options };
            delete config.options; // Remove options after merging
        }
        if (['put', 'delete', 'patch'].includes(config.method)) {
            if (config.data) {
                if (config.data instanceof FormData) {
                    config.data.append('_method', config.method);
                } else {
                    config.data = { ...config.data, _method: config.method };
                }
            } else {
                config.data = { _method: config.method };
            }
            config.method = 'post';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

let app_name = '';

createInertiaApp({
    title: (title) => (title ? `${title} - ${app_name}` : app_name),
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
        if (name.startsWith('PDF/')) {
            return page;
        }
        page.default.layout ??= (page) => <MainLayout page_name={name}>{page}</MainLayout>;
        return page;
    },
    setup({ el, App, props }) {
        const root = createRoot(el);
        const { url_theme, title: page_title } = props.initialPage.props;

        app_name =
            {
                'ya-k': "ya'K construire",
                'couleur-villas': 'Couleur Villas',
            }[url_theme] || 'Eureka';

        const app_dom = document.querySelector('html');
        let app_classes = 'theme-' + (url_theme ?? 'eureka');

        let theme = eureka_theme;
        let fonts = [
            'https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap',
            'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ];
        switch (url_theme) {
            case 'ya-k':
                theme = ya_k_theme;
                fonts = [];
                break;
            case 'couleur-villas':
                theme = couleur_villas_theme;
                break;
        }

        document.title = page_title ? `${page_title} - ${app_name}` : app_name;

        if (app_dom) {
            app_dom.classList.add(app_classes);
        }

        fonts.forEach((font_url) => {
            loadFont(font_url);
        });

        root.render(
            <>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <NotificationsProvider>
                        <App {...props} />
                    </NotificationsProvider>
                </ThemeProvider>
            </>,
        );
    },
    progress: {
        color: '#4B5563',
    },
});
