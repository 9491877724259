import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(
    (config) => {
        if (config?.options) {
            config = { ...config, ...config.options };
            delete config.options; // Remove options after merging
        }
        if (['put', 'delete', 'patch'].includes(config.method)) {
            if (config.data) {
                if (config.data instanceof FormData) {
                    config.data.append('_method', config.method);
                } else {
                    config.data = { ...config.data, _method: config.method };
                }
            } else {
                config.data = { _method: config.method };
            }
            config.method = 'post';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
