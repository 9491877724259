const ImagesStyle = {
    aspect_ratio: (ratio) => {
        const [ratio_w, ratio_h] = ratio.split(':');
        return {
            position: 'relative',
            width: '100%',
            paddingTop: (ratio_h / ratio_w) * 100 + '%',
            overflow: 'hidden',

            '& > img': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            },
        };
    },
    cover: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    cover_center: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        objectPosition: 'center',
    },
    contain: {
        objectFit: 'contain',
        width: '100%',
        height: 'auto',
    },
    contain_center: {
        objectFit: 'contain',
        width: '100%',
        height: 'auto',
        objectPosition: 'center',
    },
    background_cover: (image_src) => ({
        backgroundImage: `url('${image_src}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundOrigin: 'content-box',
    }),
    background_contain: (image_src) => ({
        backgroundImage: `url('${image_src}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundOrigin: 'content-box',
    }),
};

export default ImagesStyle;
